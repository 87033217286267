
import { defineComponent, SetupContext, ref, Ref, watch, onMounted } from 'vue'
import { Toaster } from '@/common/Toaster'
import { Contract } from '@/model/Contract'
import FormCard from '../FormCard.vue'
import ContractForm from './ContractForm.vue'
import { DigInputController } from '@digithia/input/common'
import { __ } from '@/locales'

export default defineComponent({
  name: `ContractFormCard`,
  components: { FormCard, ContractForm },
  props: {
    title: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    contract: {
      type: Object,
      required: false,
    },
  },
  emits: ['validate', 'cancel'],
  setup(
    props: Readonly<{
      title?: string
      contract?: Contract
    }>,
    context: SetupContext,
  ) {
    const contractForm: Ref<Contract> = ref({})

    onMounted(() => {
      contractForm.value = JSON.parse(JSON.stringify(props.contract))
    })

    watch(
      () => props.contract,
      () => {
        contractForm.value = JSON.parse(JSON.stringify(props.contract))
      },
    )

    const update = ({ key, value }: { key: keyof Contract; value: any }) => {
      contractForm.value[key] = value
    }

    const validate = () => {
      if (DigInputController.checkInputsValidity('.form-card')) {
        context.emit('validate', contractForm.value)
      } else {
        Toaster.toast({
          type: 'warning',
          message: __(`invalidForm`),
        })
      }
    }

    const cancel = (noEvent = false) => {
      contractForm.value = JSON.parse(JSON.stringify(props.contract))
      if (noEvent) return
      context.emit('cancel')
    }

    return {
      contractForm,
      update,
      validate,
      cancel,
    }
  },
})
