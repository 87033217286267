import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-5a51ca91")
const _hoisted_1 = { class: "card-icons" }
const _hoisted_2 = ["title"]
const _hoisted_3 = ["title"]
const _hoisted_4 = ["title"]
const _hoisted_5 = { class: "date" }
const _hoisted_6 = { class: "prix" }
const _hoisted_7 = { class: "points" }
const _hoisted_8 = { class: "center title-3 small-margin" }
const _hoisted_9 = { key: 1 }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_control_grid = _resolveComponent("base-control-grid")!
  const _component_contract_form_card = _resolveComponent("contract-form-card")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createBlock(_component_base_card, {
    class: _normalizeClass(["card-contract", { editing: _ctx.editing }])
  }, {
    default: _withCtx(() => [
      (!_ctx.editing)
        ? (_openBlock(), _createBlock(_component_base_control_grid, {
            key: 0,
            sideWidth: 140
          }, {
            right: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                (_ctx.canEdit)
                  ? (_openBlock(), _createElementBlock("dig-ui-icon", {
                      key: 0,
                      type: "edit",
                      class: "icon-button",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.editing = true)),
                      title: _ctx.$t('edit')
                    }, null, 8, _hoisted_2))
                  : _createCommentVNode("", true),
                (_ctx.canEdit)
                  ? (_openBlock(), _createElementBlock("dig-ui-icon", {
                      key: 1,
                      type: "add",
                      class: "icon-button",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.duplicateContract())),
                      title: _ctx.$t('duplicate')
                    }, null, 8, _hoisted_3))
                  : _createCommentVNode("", true),
                (_ctx.canEdit)
                  ? (_openBlock(), _createElementBlock("dig-ui-icon", {
                      key: 2,
                      type: "delete",
                      class: "icon-button",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deleteContract())),
                      title: _ctx.$t('delete')
                    }, null, 8, _hoisted_4))
                  : _createCommentVNode("", true)
              ])
            ]),
            left: _withCtx(() => [
              _createElementVNode("dig-ui-badge", _hoisted_5, _toDisplayString(_ctx.toHumanDate(_ctx.contract.date || '')), 1),
              _createElementVNode("dig-ui-badge", _hoisted_6, _toDisplayString(_ctx.contract.price) + " €HT ", 1),
              _createElementVNode("dig-ui-badge", _hoisted_7, _toDisplayString(_ctx.contract.points) + " pts ", 1)
            ]),
            middle: _withCtx(() => [
              _createElementVNode("dig-ui-title", _hoisted_8, _toDisplayString(_ctx.contract.name), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.editing)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createVNode(_component_contract_form_card, {
              class: "form",
              title: _ctx.$t('editContract'),
              onValidate: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateContract($event))),
              onCancel: _cache[4] || (_cache[4] = ($event: any) => (_ctx.editing = false)),
              disabled: _ctx.disabledFormButton,
              contract: _ctx.contract
            }, null, 8, ["title", "disabled", "contract"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class"]))
}