
import { defineComponent, SetupContext, ref, onMounted } from 'vue'
import router from '@/router'
import { storeUser, StoreActionsUser } from '@/store/user'

export default defineComponent({
  name: `CardUser`,
  components: {},
  props: {
    user: {
      type: Object,
      required: false,
    },
  },
  emits: ['add'],
  setup(props: Readonly<any>, context: SetupContext) {
    const src = ref('')

    onMounted(async () => {
      const res = await storeUser.dispatch(
        StoreActionsUser.GET_IMAGE,
        props.user.id,
      )
      src.value = res.data
    })

    const goToUser = () => {
      router.push({ name: 'User', params: { id: '' + props.user.id } })
    }

    return {
      src,
      goToUser,
    }
  },
})
