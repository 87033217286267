import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-a9e82118")
const _hoisted_1 = {
  key: 0,
  class: "padding"
}
const _hoisted_2 = {
  key: 1,
  class: "tab-gap"
}
const _hoisted_3 = {
  key: 0,
  class: "padding"
}
const _hoisted_4 = ["pageCount", "currentPage", "previous-text", "next-text"]
const _hoisted_5 = ["pageCount", "currentPage", "previous-text", "next-text"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_add_story = _resolveComponent("add-story")!
  const _component_card_story = _resolveComponent("card-story")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("dig-ui-loader", _hoisted_1))
      : _createCommentVNode("", true),
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (!_ctx.sortedStories.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('noStory')), 1))
            : _createCommentVNode("", true),
          (_ctx.sortedStories.length > _ctx.elementsPerPage)
            ? (_openBlock(), _createElementBlock("dig-ui-pagination", {
                key: 1,
                class: "padding",
                pageCount: Math.ceil(_ctx.sortedStories.length / _ctx.elementsPerPage),
                onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.currentPage = $event.detail)),
                currentPage: _ctx.currentPage,
                "previous-text": _ctx.$t('previousShort'),
                "next-text": _ctx.$t('nextShort')
              }, null, 40, _hoisted_4))
            : _createCommentVNode("", true),
          (_ctx.canAddStory)
            ? (_openBlock(), _createBlock(_component_add_story, {
                key: 2,
                ref: "addStoryEl",
                onAdd: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addStory($event)))
              }, null, 512))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedStories.filter(
        (s, index) =>
          index >= (_ctx.currentPage - 1) * _ctx.elementsPerPage &&
          index < _ctx.currentPage * _ctx.elementsPerPage,
      ), (story) => {
            return (_openBlock(), _createBlock(_component_card_story, {
              key: story.id,
              story: story
            }, null, 8, ["story"]))
          }), 128)),
          (_ctx.sortedStories.length > _ctx.elementsPerPage)
            ? (_openBlock(), _createElementBlock("dig-ui-pagination", {
                key: 3,
                class: "padding",
                pageCount: Math.ceil(_ctx.sortedStories.length / _ctx.elementsPerPage),
                onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.currentPage = $event.detail)),
                currentPage: _ctx.currentPage,
                "previous-text": _ctx.$t('previousShort'),
                "next-text": _ctx.$t('nextShort')
              }, null, 40, _hoisted_5))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}