
import { defineComponent, SetupContext, computed, onMounted } from 'vue'
import ProjectTabContract from '@/components/core/project/ProjectTabContract.vue'
import ProjectTabStory from '@/components/core/project/ProjectTabStory.vue'
import ProjectTabUser from '@/components/core/project/ProjectTabUser.vue'
import ProjectTabInvoice from '@/components/core/project/ProjectTabInvoice.vue'
import { USER_ROLES, User } from '@/model/User'
import { Story } from '@/model/Story'
import { Contract } from '@/model/Contract'
import { __ } from '@/locales'
import { storeAuth, StoreGettersAuth } from '@/store/auth'
import { storeProject, StoreGettersProject } from '@/store/project'
import { storeContract, StoreGettersContract } from '@/store/contract'
import { storeStory, StoreGettersStory } from '@/store/story'

export default defineComponent({
  name: `CardProjectTabs`,
  components: {
    ProjectTabContract,
    ProjectTabStory,
    ProjectTabUser,
    ProjectTabInvoice,
  },
  props: {
    projectId: {
      type: Number,
      required: true,
    },
  },
  emits: ['validate', 'cancel'],
  setup(
    props: Readonly<{
      projectId: number
    }>,
    context: SetupContext,
  ) {
    const isSuperadmin = computed(
      () => storeAuth.getters[StoreGettersAuth.ROLE] === USER_ROLES.SUPERADMIN,
    )
    const stories = computed(
      (): Story[] => storeStory.getters[StoreGettersStory.STORIES],
    )
    const contracts = computed(
      (): Contract[] => storeContract.getters[StoreGettersContract.CONTRACTS],
    )
    const invoices = computed(
      (): File[] => storeProject.getters[StoreGettersProject.INVOICES],
    )
    const users = computed(
      () => storeProject.getters[StoreGettersProject.USERS] as User[],
    )

    onMounted(() => {
      setTimeout(() => {
        const selectedTab = localStorage.getItem('project-selected-tab')
        const tabs = document.querySelectorAll('dig-ui-tab')
        if (selectedTab) {
          tabs.forEach((tab, index) => {
            if (index === +selectedTab) {
              tab.setAttribute('selected', '')
            }
          })
        } else {
          tabs[0].setAttribute('selected', '')
        }
      })
    })

    const changeTab = (event: CustomEvent) => {
      localStorage.setItem('project-selected-tab', event.detail)
    }

    return {
      isSuperadmin,
      stories,
      contracts,
      invoices,
      users,
      changeTab,
    }
  },
})
