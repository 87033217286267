import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-7d7ad155")
const _hoisted_1 = ["value"]
const _hoisted_2 = ["value"]
const _hoisted_3 = ["value"]
const _hoisted_4 = ["value"]
const _hoisted_5 = ["value"]
const _hoisted_6 = ["value"]
const _hoisted_7 = ["value", "label"]
const _hoisted_8 = { value: true }
const _hoisted_9 = { value: false }
const _hoisted_10 = ["value", "label"]
const _hoisted_11 = { value: true }
const _hoisted_12 = { value: false }
const _hoisted_13 = ["value"]
const _hoisted_14 = ["value"]
const _hoisted_15 = ["value"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "projectFormEl",
    class: _normalizeClass(["project-form", { mobile: _ctx.mobile, desktop: !_ctx.mobile }])
  }, [
    _createElementVNode("dig-input-text", {
      value: _ctx.project.name,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.update('name', $event.target.value))),
      id: "name",
      required: ""
    }, _toDisplayString(_ctx.$t('Project.name')), 41, _hoisted_1),
    _createElementVNode("dig-input-number", {
      value: _ctx.project.price,
      onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.update('price', $event.target.value))),
      suffix: "€",
      id: "price",
      required: ""
    }, _toDisplayString(_ctx.$t('Project.price')), 41, _hoisted_2),
    _createElementVNode("dig-input-number", {
      value: _ctx.project.points,
      onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.update('points', $event.target.value))),
      id: "points",
      required: ""
    }, _toDisplayString(_ctx.$t('Project.points')), 41, _hoisted_3),
    _createElementVNode("dig-input-text", {
      value: _ctx.project.url,
      onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.update('url', $event.target.value))),
      id: "url"
    }, _toDisplayString(_ctx.$t('Project.url')), 41, _hoisted_4),
    _createElementVNode("dig-input-text", {
      value: _ctx.project.testurl,
      onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.update('testurl', $event.target.value))),
      id: "testurl"
    }, _toDisplayString(_ctx.$t('Project.testurl')), 41, _hoisted_5),
    _createElementVNode("dig-input-text", {
      value: _ctx.project.version,
      onInput: _cache[5] || (_cache[5] = ($event: any) => (_ctx.update('version', $event.target.value))),
      id: "version"
    }, _toDisplayString(_ctx.$t('Project.version')), 41, _hoisted_6),
    _createElementVNode("dig-input-radio-wrapper", {
      value: _ctx.project.active,
      onInput: _cache[6] || (_cache[6] = ($event: any) => (_ctx.update('active', $event.target.value))),
      id: "active",
      label: _ctx.$t('Project.active')
    }, [
      _createElementVNode("dig-input-radio", _hoisted_8, _toDisplayString(_ctx.$t('activated')), 1),
      _createElementVNode("dig-input-radio", _hoisted_9, _toDisplayString(_ctx.$t('desactivated')), 1)
    ], 40, _hoisted_7),
    _createElementVNode("dig-input-radio-wrapper", {
      value: _ctx.project.intervention,
      onInput: _cache[7] || (_cache[7] = ($event: any) => (_ctx.update('intervention', $event.target.value))),
      id: "intervention",
      label: _ctx.$t('Project.intervention')
    }, [
      _createElementVNode("dig-input-radio", _hoisted_11, _toDisplayString(_ctx.$t('activated')), 1),
      _createElementVNode("dig-input-radio", _hoisted_12, _toDisplayString(_ctx.$t('desactivated')), 1)
    ], 40, _hoisted_10),
    _createElementVNode("dig-input-number", {
      value: _ctx.project.interventionPoint,
      onInput: _cache[8] || (_cache[8] = ($event: any) => (_ctx.update('interventionPoint', $event.target.value))),
      id: "interventionPoint"
    }, _toDisplayString(_ctx.$t('Project.interventionPoint')), 41, _hoisted_13),
    _createElementVNode("dig-input-text", {
      value: _ctx.project.interventionName,
      onInput: _cache[9] || (_cache[9] = ($event: any) => (_ctx.update('interventionName', $event.target.value))),
      id: "interventionName"
    }, _toDisplayString(_ctx.$t('Project.interventionName')), 41, _hoisted_14),
    _createElementVNode("dig-input-textarea", {
      value: _ctx.project.interventionDescription,
      onInput: _cache[10] || (_cache[10] = ($event: any) => (_ctx.update('interventionDescription', $event.target.value))),
      id: "interventionDescription"
    }, _toDisplayString(_ctx.$t('Project.interventionDescription')), 41, _hoisted_15)
  ], 2))
}