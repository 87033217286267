
import { defineComponent, SetupContext, ref, Ref } from 'vue'
import ContractFormCard from './ContractFormCard.vue'
import { Contract } from '@/model/Contract'

export default defineComponent({
  name: `AddContract`,
  components: { ContractFormCard },
  props: {},
  emits: ['add'],
  setup(props: Readonly<unknown>, context: SetupContext) {
    const contract: Ref<Contract> = ref({})
    const adding = ref(false)

    const addContract = (contract: Contract) => {
      context.emit('add', contract)
    }

    const reset = () => {
      adding.value = false
      contract.value = {}
    }

    return {
      contract,
      adding,
      addContract,
      reset,
    }
  },
})
