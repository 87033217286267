
import {
  defineComponent,
  SetupContext,
  computed,
  ref,
  onMounted,
  watch,
  Ref,
} from 'vue'
import AddContract from '@/components/core/contract/AddContract.vue'
import CardContract from '@/components/core/contract/CardContract.vue'
import { Project } from '@/model/Project'
import { utils } from '@/common/Utils'
import { USER_ROLES } from '@/model/User'
import { Contract } from '@/model/Contract'
import { Toaster } from '@/common/Toaster'
import { __ } from '@/locales'
import { storeAuth, StoreGettersAuth } from '@/store/auth'
import { storeProject, StoreGettersProject } from '@/store/project'
import {
  storeContract,
  StoreGettersContract,
  StoreActionsContract,
} from '@/store/contract'
import { ResetableComponent } from '@/model/ResetableComponent'

export default defineComponent({
  name: `ProjectTabContract`,
  components: {
    AddContract,
    CardContract,
  },
  props: {
    projectId: {
      type: Number,
      required: true,
    },
  },
  emits: ['validate', 'cancel'],
  setup(
    props: Readonly<{
      projectId: number
    }>,
    context: SetupContext,
  ) {
    const addContractEl: Ref<ResetableComponent> = ref(null)
    const loading = ref(false)
    const elementsPerPage = ref(12)
    const currentPage = ref(1)

    const isSuperadmin = computed(
      () => storeAuth.getters[StoreGettersAuth.ROLE] === USER_ROLES.SUPERADMIN,
    )
    const contracts = computed(
      (): Contract[] => storeContract.getters[StoreGettersContract.CONTRACTS],
    )
    const sortedContracts = computed(() => {
      return storeContract.getters[StoreGettersContract.CONTRACTS].sort(
        (a: Contract, b: Contract) => {
          return utils.UTCToMoment(b.date).diff(utils.UTCToMoment(a.date))
        },
      ) as Contract[]
    })
    const canAddContract = computed(
      () => storeAuth.getters[StoreGettersAuth.ROLE] === USER_ROLES.SUPERADMIN,
    )
    const project = computed(
      (): Project => storeProject.getters[StoreGettersProject.PROJECT],
    )

    watch(
      () => props.projectId,
      () => {
        if (!props.projectId) return
        if (props.projectId === project.value.id) return
        loadContent()
      },
    )

    onMounted(() => {
      loadContent()
    })

    const addContract = async (contract: Contract) => {
      const res = await storeContract.dispatch(StoreActionsContract.ADD, {
        ...contract,
        projectId: props.projectId,
      })
      Toaster.toast(res)
      if (res.success) {
        addContractEl.value.reset()
      }
    }

    const loadContent = async () => {
      loading.value = true
      const res = storeContract.dispatch(StoreActionsContract.SEARCH, {
        projectId: props.projectId,
      })
      res.then(() => (loading.value = false))
    }

    return {
      loading,
      addContractEl,
      isSuperadmin,
      contracts,
      sortedContracts,
      canAddContract,
      addContract,
      elementsPerPage,
      currentPage,
    }
  },
})
