import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withScopeId as _withScopeId } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_project_form = _resolveComponent("project-form")!
  const _component_form_card = _resolveComponent("form-card")!

  return (_openBlock(), _createBlock(_component_form_card, {
    class: "form-card",
    title: _ctx.title,
    onValidate: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validate())),
    onCancel: _cache[2] || (_cache[2] = ($event: any) => (_ctx.cancel())),
    disabled: _ctx.disabled
  }, {
    default: _withCtx(() => [
      _createVNode(_component_project_form, {
        project: _ctx.projectForm,
        onUpdate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.update($event)))
      }, null, 8, ["project"])
    ]),
    _: 1
  }, 8, ["title", "disabled"]))
}