
import { Story } from '@/model/Story'
import { downloadFile, utils } from '@/common/Utils'
import { USER_ROLES } from '@/model/User'
import StoryFormCard from './StoryFormCard.vue'
import { Toaster } from '@/common/Toaster'
import router from '@/router'
import { __ } from '@/locales'
import { storeAuth, StoreGettersAuth } from '@/store/auth'
import { defineComponent, computed, SetupContext, ref } from 'vue'
import { storeStory, StoreActionsStory } from '@/store/story'

export default defineComponent({
  name: `CardStory`,
  components: { StoryFormCard },
  props: {
    story: {
      type: Object,
      required: true,
    },
  },
  emits: ['validate', 'cancel'],
  setup(props: Readonly<any>, context: SetupContext) {
    const disabledFormButton = ref(false)
    const editing = ref(false)

    const date = computed((): string => utils.UTCToShort(props.story.date))

    const canEdit = computed(
      (): boolean =>
        storeAuth.getters[StoreGettersAuth.ROLE] === USER_ROLES.SUPERADMIN,
    )

    const goToStory = () => {
      router.push(`/stories/${props.story.id}`)
    }

    const updateStory = async (story: Story) => {
      disabledFormButton.value = true
      const res = await storeStory.dispatch(StoreActionsStory.UPDATE, {
        story,
        id: story.id,
      })
      Toaster.toast(res)
      if (res.success) {
        editing.value = false
      }
      disabledFormButton.value = false
    }

    const deleteStory = async () => {
      if (confirm(__('Story.askDelete').toString())) {
        const res = await storeStory.dispatch(
          StoreActionsStory.DELETE,
          props.story.id,
        )
        Toaster.toast(res)
      }
    }

    const duplicateStory = async () => {
      const res = await storeStory.dispatch(
        StoreActionsStory.DUPLICATE,
        props.story.id,
      )
      Toaster.toast(res)
    }

    const addReport = async (event: CustomEvent) => {
      Toaster.toast({ type: 'info', message: __('downloading').toString() })
      const res = await storeStory.dispatch(StoreActionsStory.SET_REPORT, {
        base64: event.detail.base64,
        file: event.detail.file,
        storyId: props.story.id,
      })
      Toaster.toast(res)
    }

    const downloadReport = async () => {
      const res = await storeStory.dispatch(
        StoreActionsStory.GET_REPORT,
        props.story.id,
      )
      downloadFile(res.data, `Rapport ${props.story.name}.pdf`)
    }

    return {
      editing,
      date,
      canEdit,
      disabledFormButton,
      goToStory,
      updateStory,
      deleteStory,
      duplicateStory,
      addReport,
      downloadReport,
    }
  },
})
