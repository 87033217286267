
import { defineComponent, SetupContext, computed, ref, watch, Ref } from 'vue'
import { Project } from '@/model/Project'
import { goTo } from '@/router'
import { storeProject, StoreGettersProject } from '@/store/project'
import {
  storeDemand,
  StoreActionsDemand,
  StoreGettersDemand,
} from '@/store/demand'
import { DEMAND_STATES, Demand, defaultSearchDemand } from '@/model/Demand'

export default defineComponent({
  name: `CardProjectDemands`,
  components: {},
  props: {
    projectId: {
      type: Number,
      required: true,
    },
  },
  emits: ['validate', 'cancel'],
  setup(
    props: Readonly<{
      projectId: number
    }>,
    context: SetupContext,
  ) {
    const loading = ref(false)

    watch(
      () => props.projectId,
      async () => {
        if (!props.projectId) return
        if (props.projectId === project.value.id) return

        loading.value = true
        await storeDemand.dispatch(StoreActionsDemand.SEARCH, {
          projectId: props.projectId,
        })
        loading.value = false
      },
    )

    const allDemands: Ref<Demand[]> = computed(() => {
      return storeDemand.getters[StoreGettersDemand.DEMANDS]
    })
    const project = computed(
      (): Project => storeProject.getters[StoreGettersProject.PROJECT],
    )

    const goToDemands = async (state?: DEMAND_STATES) => {
      if (state) {
        await storeDemand.dispatch(StoreActionsDemand.UPDATE_SEARCH_DEMAND, {
          ...defaultSearchDemand,
          state: [state],
        })
      }
      goTo(`/projects/${props.projectId}/requests`)
    }

    return {
      goTo,
      project,
      loading,
      allDemands,
      DEMAND_STATES,
      goToDemands,
    }
  },
})
