
import { defineComponent, ref, onMounted, watch } from 'vue'
import CardProjectDetail from '@/components/core/project/CardProjectDetail.vue'
import CardProjectTabs from '@/components/core/project/CardProjectTabs.vue'
import CardProjectDemands from '@/components/core/project/CardProjectDemands.vue'
import { useRoute } from 'vue-router'
import { storeAuth, StoreGettersAuth } from '@/store/auth'

export default defineComponent({
  name: `ViewProject`,
  components: {
    CardProjectDetail,
    CardProjectTabs,
    CardProjectDemands,
  },
  props: {},
  emits: [],
  setup() {
    const route = useRoute()
    const projectId = ref(0)

    onMounted(() => {
      const id = +route.params.id
      init(id)
    })

    watch(route, () => {
      const id = +route.params.id
      init(id)
    })

    const init = async (id: number) => {
      if (!id && storeAuth.getters[StoreGettersAuth.USER].projectIds) {
        id = storeAuth.getters[StoreGettersAuth.USER].projectIds[0]
      }
      projectId.value = id
    }

    return {
      projectId,
    }
  },
})
