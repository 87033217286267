
import {
  defineComponent,
  SetupContext,
  computed,
  ref,
  watch,
  ComputedRef,
} from 'vue'
import ProjectFormCard from '@/components/core/project/ProjectFormCard.vue'
import { getAvailablePoints, Project } from '@/model/Project'
import { USER_ROLES } from '@/model/User'
import { Toaster } from '@/common/Toaster'
import router, { goTo } from '@/router'
import { __ } from '@/locales'
import { storeAuth, StoreGettersAuth } from '@/store/auth'
import {
  storeProject,
  StoreGettersProject,
  StoreActionsProject,
} from '@/store/project'
import { storeContract, StoreGettersContract } from '@/store/contract'
import { storeStory, StoreGettersStory } from '@/store/story'

export default defineComponent({
  name: `CardProjectDetail`,
  components: { ProjectFormCard },
  props: {
    projectId: {
      type: Number,
      required: true,
    },
  },
  emits: ['validate', 'cancel'],
  setup(
    props: Readonly<{
      projectId: number
    }>,
    context: SetupContext,
  ) {
    const disabledFormButton = ref(false)
    const editing = ref(false)
    const loading = ref(false)
    const availablePoints: ComputedRef<number> = computed(() => {
      return getAvailablePoints()
    })

    watch(
      () => props.projectId,
      async () => {
        if (!props.projectId) return
        if (props.projectId === project.value.id) return

        loading.value = true
        storeProject.dispatch(StoreActionsProject.GET_IMAGE, props.projectId)
        await storeProject.dispatch(StoreActionsProject.GET, props.projectId)
        loading.value = false
      },
    )
    const src = computed(() => storeProject.getters[StoreGettersProject.IMAGE])
    const isSuperadmin = computed(
      () => storeAuth.getters[StoreGettersAuth.ROLE] === USER_ROLES.SUPERADMIN,
    )
    const isAdmin = computed(
      () =>
        storeAuth.getters[StoreGettersAuth.ROLE] === USER_ROLES.ADMIN ||
        storeAuth.getters[StoreGettersAuth.ROLE] === USER_ROLES.SUPERADMIN,
    )
    const project = computed(
      (): Project => storeProject.getters[StoreGettersProject.PROJECT],
    )
    const canEditImage = computed(
      () =>
        storeAuth.getters[StoreGettersAuth.ROLE] === USER_ROLES.SUPERADMIN ||
        storeAuth.getters[StoreGettersAuth.ROLE] === USER_ROLES.ADMIN,
    )
    const canEdit = computed(
      () => storeAuth.getters[StoreGettersAuth.ROLE] === USER_ROLES.SUPERADMIN,
    )

    const updateProject = async (project: Project) => {
      disabledFormButton.value = true
      const res = await storeProject.dispatch(StoreActionsProject.UPDATE, {
        project: project,
        id: props.projectId,
      })
      Toaster.toast(res)
      if (res.success) {
        editing.value = false
      }
      disabledFormButton.value = false
    }

    const deleteProject = async () => {
      if (confirm(__(`Project.askDelete`))) {
        const res = await storeProject.dispatch(
          StoreActionsProject.DELETE,
          props.projectId,
        )
        Toaster.toast(res)
        if (res.success) {
          router.back()
        }
      }
    }

    const saveProjectImage = async (event: CustomEvent) => {
      const file = event.detail.file
      const res = await storeProject.dispatch(StoreActionsProject.SET_IMAGE, {
        base64: event.detail.base64,
        file: { name: file.name },
        projectId: props.projectId,
      })
      Toaster.toast(res)
    }

    const deleteProjectImage = async () => {
      const res = await storeProject.dispatch(
        StoreActionsProject.DELETE_IMAGE,
        props.projectId,
      )
      Toaster.toast(res)
    }

    const openLink = (link?: string, blank = true) => {
      window.open(link, blank ? '_blank' : undefined)
    }

    return {
      goTo,
      src,
      project,
      editing,
      isSuperadmin,
      isAdmin,
      canEditImage,
      canEdit,
      updateProject,
      deleteProject,
      saveProjectImage,
      deleteProjectImage,
      openLink,
      disabledFormButton,
      loading,
      availablePoints,
      getAvailablePoints,
    }
  },
})
