
import { defineComponent, SetupContext } from 'vue'
import { Contract } from '@/model/Contract'

export default defineComponent({
  name: `ContractForm`,
  components: {},
  props: {
    contract: {
      type: Object,
      required: true,
    },
  },
  emits: ['update'],
  setup(
    props: Readonly<{
      contract?: Contract
    }>,
    context: SetupContext,
  ) {
    const update = (key: keyof Contract, value: any) => {
      context.emit('update', { key, value })
    }
    return { update }
  },
})
