import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withScopeId as _withScopeId } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_card_project_detail = _resolveComponent("card-project-detail")!
  const _component_card_project_demands = _resolveComponent("card-project-demands")!
  const _component_card_project_tabs = _resolveComponent("card-project-tabs")!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, {
    marginTop: "",
    marginBottom: "",
    class: "view-project"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_card_project_detail, { projectId: _ctx.projectId }, null, 8, ["projectId"]),
      _createVNode(_component_card_project_demands, { projectId: _ctx.projectId }, null, 8, ["projectId"]),
      _createVNode(_component_card_project_tabs, { projectId: _ctx.projectId }, null, 8, ["projectId"])
    ]),
    _: 1
  }))
}