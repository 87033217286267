import { GetterTree, MutationTree, ActionTree, createStore, Store } from 'vuex'
import { dashboardApi } from '@/api/DashboardApi'
import {
  Demand,
  defaultDemand,
  defaultSearchDemand,
  SearchDemand,
} from '@/model/Demand'
import { InjectionKey } from 'vue'

export interface StoreStateDemand {
  demand: Demand
  searchDemand: SearchDemand
  demands: Demand[]
}

export enum StoreGettersDemand {
  DEMAND = 'DEMAND',
  SEARCH_DEMAND = 'SEARCH_DEMAND',
  DEMANDS = 'DEMANDS',
}

export enum StoreActionsDemand {
  GET_ALL = 'GET_ALL',
  GET = 'GET',
  ADD = 'ADD',
  UPDATE = 'UPDATE',
  UPDATE_FIELD = 'UPDATE_FIELD',
  DELETE = 'DELETE',
  SEARCH = 'SEARCH',
  GET_FILE = 'GET_FILE',
  GET_FILES = 'GET_FILES',
  ADD_FILE = 'ADD_FILE',
  DELETE_FILE = 'DELETE_FILE',
  DELETE_FILES = 'DELETE_FILES',
  UPDATE_SEARCH_DEMAND = 'UPDATE_SEARCH_DEMAND',
}

export enum StoreMutationsDemand {
  SAVE_DEMANDS = 'SAVE_DEMANDS',
  SAVE_DEMAND = 'SAVE_DEMAND',
  UPDATE_DEMAND = 'UPDATE_DEMAND',
  SHIFT_DEMAND = 'SHIFT_DEMAND',
  SET_SEARCH_DEMAND = 'SET_SEARCH_DEMAND',
}

const state: StoreStateDemand = {
  demand: defaultDemand,
  searchDemand: defaultSearchDemand,
  demands: [],
}

const getters: GetterTree<StoreStateDemand, StoreStateDemand> = {
  [StoreGettersDemand.DEMAND](state): Demand {
    return state.demand
  },
  [StoreGettersDemand.SEARCH_DEMAND](state): SearchDemand {
    return state.searchDemand
  },
  [StoreGettersDemand.DEMANDS](state): Demand[] {
    return state.demands
  },
}

const actions: ActionTree<StoreStateDemand, StoreStateDemand> = {
  async [StoreActionsDemand.GET_ALL]({ commit }) {
    commit('SAVE_DEMANDS', [])
    const json = await dashboardApi.get('demands')
    if (json.success) {
      commit('SAVE_DEMANDS', json.data)
    } else {
      commit('SAVE_DEMANDS', [])
    }
    return json
  },
  async [StoreActionsDemand.GET]({ commit }, id: number) {
    const json = await dashboardApi.get(`demands/${id}`)
    if (json.success) {
      commit('SAVE_DEMAND', json.data)
    }
    return json
  },
  async [StoreActionsDemand.ADD]({ commit }, demand: Demand) {
    const json = await dashboardApi.post('demands', demand)
    if (json.success) {
      commit('SAVE_DEMAND', json.data)
    }
    return json
  },
  async [StoreActionsDemand.UPDATE]({ commit }, { demand, id }) {
    const json = await dashboardApi.put(`demands/${id}`, demand)
    if (json.success) {
      commit('UPDATE_DEMAND', json.data)
    }
    return json
  },
  async [StoreActionsDemand.UPDATE_FIELD]({ commit }, { field, value, id }) {
    const json = await dashboardApi.put(`demands/${id}/field`, { field, value })
    if (json.success) {
      commit('UPDATE_DEMAND', json.data)
    }
    return json
  },
  async [StoreActionsDemand.DELETE]({ commit }, id: number) {
    const json = await dashboardApi.delete(`demands/${id}`)
    if (json.success) {
      commit('SHIFT_DEMAND', id)
    }
    return json
  },
  async [StoreActionsDemand.SEARCH]({ commit }, searchdata: Demand) {
    commit('SAVE_DEMANDS', [])
    const json = await dashboardApi.post('demands/search', searchdata)
    commit('SAVE_DEMANDS', json.data)
    return json
  },
  async [StoreActionsDemand.GET_FILE]({ commit }, { id, filename }) {
    const json = await dashboardApi.get(`demands/${id}/files/${filename}`)
    return json
  },
  async [StoreActionsDemand.GET_FILES]({ commit }, id: number) {
    const json = await dashboardApi.get(`demands/${id}/files`)
    return json
  },
  async [StoreActionsDemand.ADD_FILE]({ commit }, { id, base64, filename }) {
    const json = await dashboardApi.post(`demands/${id}/files`, {
      filename,
      base64,
    })
    const res = await dashboardApi.get(`demands/${id}`)
    commit(StoreMutationsDemand.UPDATE_DEMAND, res.data)
    return json
  },
  async [StoreActionsDemand.DELETE_FILE]({ commit }, { id, filename }) {
    const json = await dashboardApi.delete(`demands/${id}/files/${filename}`)
    const res = await dashboardApi.get(`demands/${id}`)
    commit(StoreMutationsDemand.UPDATE_DEMAND, res.data)
    return json
  },
  async [StoreActionsDemand.DELETE_FILES]({ commit }, id: number) {
    const json = await dashboardApi.delete(`demands/${id}/files`)
    const res = await dashboardApi.get(`demands/${id}`)
    commit(StoreMutationsDemand.UPDATE_DEMAND, res.data)
    return json
  },
  async [StoreActionsDemand.UPDATE_SEARCH_DEMAND](
    { commit },
    searchDemand: SearchDemand,
  ) {
    commit(StoreMutationsDemand.SET_SEARCH_DEMAND, searchDemand)
  },
}

const mutations: MutationTree<StoreStateDemand> = {
  [StoreMutationsDemand.SAVE_DEMANDS](state, demands: Demand[] = []) {
    state.demands = demands
  },
  [StoreMutationsDemand.SAVE_DEMAND](state, demand: Demand) {
    if (demand) state.demands.push(demand)
  },
  [StoreMutationsDemand.UPDATE_DEMAND](state, demand: Demand) {
    if (demand) {
      state.demands = state.demands.map((c) => {
        if (c.id === demand.id) {
          return demand
        }
        return c
      })
    }
  },
  [StoreMutationsDemand.SHIFT_DEMAND](state, id: number) {
    state.demands = state.demands.filter((s) => s.id !== id)
  },
  [StoreMutationsDemand.SET_SEARCH_DEMAND](state, searchDemand: SearchDemand) {
    state.searchDemand = searchDemand
  },
}
export const key: InjectionKey<Store<StoreStateDemand>> = Symbol('')

export const storeDemand = createStore<StoreStateDemand>({
  state,
  mutations,
  getters,
  actions,
})
