
import { defineComponent, ref, Ref, SetupContext, onMounted } from 'vue'
import { Project, DEFAULT_PROJECT } from '@/model/Project'
import FormCard from '../FormCard.vue'
import ProjectForm from './ProjectForm.vue'
import { Toaster } from '@/common/Toaster'
import { DigInputController } from '@digithia/input/common'
import { __ } from '@/locales'

export default defineComponent({
  name: `ProjectFormCard`,
  components: { FormCard, ProjectForm },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    project: {
      type: Object,
      required: false,
      default: () => {
        return {}
      },
    },
  },
  emits: ['validate', 'cancel'],
  setup(
    props: Readonly<{
      title?: string
      project?: Project
    }>,
    context: SetupContext,
  ) {
    const projectForm: Ref<Project> = ref(DEFAULT_PROJECT)

    onMounted(() => {
      projectForm.value = JSON.parse(JSON.stringify(props.project))
    })

    const update = ({ key, value }: { key: keyof Project; value: any }) => {
      projectForm.value[key] = value
    }

    const validate = () => {
      if (DigInputController.checkInputsValidity('.form-card')) {
        context.emit('validate', projectForm.value)
      } else {
        Toaster.toast({
          type: 'warning',
          message: __(`invalidForm`),
        })
      }
    }

    const cancel = () => {
      projectForm.value = JSON.parse(JSON.stringify(props.project))
      context.emit('cancel')
    }

    return { validate, cancel, projectForm, update }
  },
})
