import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-2d9a668c")
const _hoisted_1 = {
  key: 0,
  class: "padding"
}
const _hoisted_2 = {
  key: 1,
  class: "padding"
}
const _hoisted_3 = {
  key: 2,
  class: "tab-users"
}
const _hoisted_4 = ["pageCount", "currentPage", "previous-text", "next-text"]
const _hoisted_5 = ["pageCount", "currentPage", "previous-text", "next-text"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_card_user = _resolveComponent("card-user")!
  const _component_add_user = _resolveComponent("add-user")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("dig-ui-loader", _hoisted_1))
      : _createCommentVNode("", true),
    (!_ctx.users.length && !_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('noUser')), 1))
      : _createCommentVNode("", true),
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.users.length > _ctx.elementsPerPage)
            ? (_openBlock(), _createElementBlock("dig-ui-pagination", {
                key: 0,
                class: "padding",
                pageCount: Math.ceil(_ctx.users.length / _ctx.elementsPerPage),
                onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.currentPage = $event.detail)),
                currentPage: _ctx.currentPage,
                "previous-text": _ctx.$t('previousShort'),
                "next-text": _ctx.$t('nextShort')
              }, null, 40, _hoisted_4))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users.filter(
        (s, index) =>
          index >= (_ctx.currentPage - 1) * _ctx.elementsPerPage &&
          index < _ctx.currentPage * _ctx.elementsPerPage,
      ), (user) => {
            return (_openBlock(), _createBlock(_component_card_user, {
              key: user.id,
              user: user,
              onDelete: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deleteUser($event))),
              onUnlink: _cache[2] || (_cache[2] = ($event: any) => (_ctx.unlinkUser($event)))
            }, null, 8, ["user"]))
          }), 128)),
          (_ctx.canAddUser)
            ? (_openBlock(), _createBlock(_component_add_user, {
                key: 1,
                ref: "addUserEl",
                onAdd: _cache[3] || (_cache[3] = ($event: any) => (_ctx.addUser($event))),
                disabled: _ctx.loadingAddUser,
                loading: _ctx.loadingAddUser,
                fixProjectId: _ctx.projectId
              }, null, 8, ["disabled", "loading", "fixProjectId"]))
            : _createCommentVNode("", true),
          (_ctx.users.length > _ctx.elementsPerPage)
            ? (_openBlock(), _createElementBlock("dig-ui-pagination", {
                key: 2,
                class: "padding",
                pageCount: Math.ceil(_ctx.users.length / _ctx.elementsPerPage),
                onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.currentPage = $event.detail)),
                currentPage: _ctx.currentPage,
                "previous-text": _ctx.$t('previousShort'),
                "next-text": _ctx.$t('nextShort')
              }, null, 40, _hoisted_5))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}