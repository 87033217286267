
import { defineComponent, onMounted, SetupContext, ref, Ref } from 'vue'
import { Project, DEFAULT_PROJECT } from '@/model/Project'

export default defineComponent({
  name: `ProjectForm`,
  components: {},
  props: {
    project: {
      type: Object,
      required: false,
      default: DEFAULT_PROJECT,
    },
  },
  emits: ['update'],
  setup(
    props: Readonly<{
      project?: Project
    }>,
    context: SetupContext,
  ) {
    const mobile = ref(false)
    const projectFormEl: Ref<HTMLElement | null> = ref(null)

    onMounted(() => {
      if ((projectFormEl.value?.clientWidth || 0) < 400) {
        mobile.value = true
      } else {
        mobile.value = false
      }
    })

    const update = (key: keyof Project, value: any) => {
      context.emit('update', { key, value })
    }

    return { mobile, update, projectFormEl }
  },
})
