
import { Contract } from '@/model/Contract'
import { utils } from '@/common/Utils'
import { USER_ROLES } from '@/model/User'
import ContractFormCard from './ContractFormCard.vue'
import { Toaster } from '@/common/Toaster'
import { __ } from '@/locales'
import { storeAuth, StoreGettersAuth } from '@/store/auth'
import { defineComponent, SetupContext, ref, computed } from 'vue'
import { storeContract, StoreActionsContract } from '@/store/contract'

export default defineComponent({
  name: `CardContract`,
  components: { ContractFormCard },
  props: {
    contract: {
      type: Object,
      required: true,
    },
  },
  emits: ['validate', 'cancel'],
  setup(props: Readonly<any>, context: SetupContext) {
    const disabledFormButton = ref(false)
    const editing = ref(false)

    const canEdit = computed(
      () => storeAuth.getters[StoreGettersAuth.ROLE] === USER_ROLES.SUPERADMIN,
    )

    const toHumanDate = (date: string): string => {
      return utils.UTCToHuman(date)
    }

    const updateContract = async (contract: Contract) => {
      disabledFormButton.value = true
      const res = await storeContract.dispatch(StoreActionsContract.UPDATE, {
        contract,
        id: contract.id,
      })
      Toaster.toast(res)
      if (res.success) {
        editing.value = false
      }
      disabledFormButton.value = false
    }

    const deleteContract = async () => {
      if (confirm(__('Contract.askDelete').toString())) {
        const res = await storeContract.dispatch(
          StoreActionsContract.DELETE,
          props.contract.id,
        )
        Toaster.toast(res)
      }
    }

    const duplicateContract = async () => {
      const res = await storeContract.dispatch(
        StoreActionsContract.DUPLICATE,
        props.contract.id,
      )
      Toaster.toast(res)
    }

    return {
      canEdit,
      toHumanDate,
      updateContract,
      deleteContract,
      duplicateContract,
      editing,
      disabledFormButton,
    }
  },
})
