
import { defineComponent, SetupContext, Ref, ref } from 'vue'
import UserFormCard from './UserFormCard.vue'
import { User } from '@/model/User'

export default defineComponent({
  name: `AddUser`,
  components: { UserFormCard },
  props: {
    fixProjectId: {
      type: Number,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    background: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['add'],
  setup(props: Readonly<any>, context: SetupContext) {
    const user: Ref<User> = ref({
      username: '',
      projectId: null,
      email: '',
      role: null,
    })

    const adding = ref(false)

    const addUser = (user: User) => {
      if (props.fixProjectId) {
        user.projectId = props.fixProjectId
      }
      context.emit('add', user)
    }

    const reset = () => {
      adding.value = false
      user.value = {}
    }

    return {
      user,
      adding,
      addUser,
      reset,
    }
  },
})
