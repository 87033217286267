import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-363f6d9d")
const _hoisted_1 = ["tab-title"]
const _hoisted_2 = ["tab-title"]
const _hoisted_3 = ["tab-title"]
const _hoisted_4 = ["tab-title"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_project_tab_story = _resolveComponent("project-tab-story")!
  const _component_project_tab_contract = _resolveComponent("project-tab-contract")!
  const _component_project_tab_user = _resolveComponent("project-tab-user")!
  const _component_project_tab_invoice = _resolveComponent("project-tab-invoice")!

  return (_ctx.projectId)
    ? (_openBlock(), _createElementBlock("dig-ui-tabset", {
        key: 0,
        onSelect: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeTab($event)))
      }, [
        _createElementVNode("dig-ui-tab", {
          "tab-title": _ctx.$tc('nbStory', _ctx.stories.length)
        }, [
          _createVNode(_component_project_tab_story, { projectId: _ctx.projectId }, null, 8, ["projectId"])
        ], 8, _hoisted_1),
        _createElementVNode("dig-ui-tab", {
          "tab-title": _ctx.$tc('nbContract', _ctx.contracts.length)
        }, [
          _createVNode(_component_project_tab_contract, { projectId: _ctx.projectId }, null, 8, ["projectId"])
        ], 8, _hoisted_2),
        _createElementVNode("dig-ui-tab", {
          "tab-title": _ctx.$tc('nbUser', _ctx.users.length)
        }, [
          _createVNode(_component_project_tab_user, { projectId: _ctx.projectId }, null, 8, ["projectId"])
        ], 8, _hoisted_3),
        _createElementVNode("dig-ui-tab", {
          "tab-title": _ctx.$tc('nbInvoice', _ctx.invoices.length)
        }, [
          _createVNode(_component_project_tab_invoice, { projectId: _ctx.projectId }, null, 8, ["projectId"])
        ], 8, _hoisted_4)
      ], 32))
    : _createCommentVNode("", true)
}