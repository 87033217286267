
import {
  defineComponent,
  SetupContext,
  computed,
  ref,
  onMounted,
  watch,
  Ref,
} from 'vue'
import { Project } from '@/model/Project'
import { downloadFile } from '@/common/Utils'
import { USER_ROLES } from '@/model/User'
import { Toaster } from '@/common/Toaster'
import { __ } from '@/locales'
import { storeAuth, StoreGettersAuth } from '@/store/auth'
import {
  storeProject,
  StoreGettersProject,
  StoreActionsProject,
} from '@/store/project'
import { ResetableComponent } from '@/model/ResetableComponent'
import router from '@/router'

export default defineComponent({
  name: `ProjectTabInvoice`,
  components: {},
  props: {
    projectId: {
      type: Number,
      required: true,
    },
  },
  emits: ['validate', 'cancel'],
  setup(
    props: Readonly<{
      projectId: number
    }>,
    context: SetupContext,
  ) {
    const addInvoiceEl: Ref<ResetableComponent> = ref(null)
    const loading = ref(false)
    const elementsPerPage = ref(12)
    const currentPage = ref(1)

    const isSuperadmin = computed(
      () => storeAuth.getters[StoreGettersAuth.ROLE] === USER_ROLES.SUPERADMIN,
    )
    const invoices = computed(
      (): File[] => storeProject.getters[StoreGettersProject.INVOICES],
    )
    const project = computed(
      (): Project => storeProject.getters[StoreGettersProject.PROJECT],
    )

    watch(
      () => props.projectId,
      () => {
        if (!props.projectId) return
        if (props.projectId === project.value.id) return
        loadContent()
      },
    )

    onMounted(() => {
      loadContent()
    })

    const loadContent = async () => {
      loading.value = true
      const resInvoice = storeProject.dispatch(
        StoreActionsProject.GET_INVOICES,
        props.projectId,
      )
      resInvoice.then(() => (loading.value = false))
    }

    const goToInvoice = (name: string) => {
      router.push({
        path: `/projects/${props.projectId}/invoices/${name}`,
      })
    }

    const downloadInvoice = async (invoice: any) => {
      downloadFile(invoice.base64, `${invoice.name}`)
    }

    const addInvoice = async (event: CustomEvent) => {
      const res = await storeProject.dispatch(StoreActionsProject.ADD_INVOICE, {
        base64: event.detail.base64,
        file: { name: event.detail.file.name },
        projectId: props.projectId,
      })
      Toaster.toast(res)
    }

    const deleteInvoice = async (invoice: any) => {
      if (confirm(__('askDeleteInvoice').toString())) {
        const res = await storeProject.dispatch(
          StoreActionsProject.DELETE_INVOICE,
          {
            name: invoice.name,
            projectId: props.projectId,
          },
        )
        Toaster.toast(res)
      }
    }

    return {
      loading,
      addInvoiceEl,
      isSuperadmin,
      invoices,
      goToInvoice,
      downloadInvoice,
      addInvoice,
      deleteInvoice,
      elementsPerPage,
      currentPage,
    }
  },
})
