
import { defineComponent, SetupContext, ref, Ref } from 'vue'
import StoryFormCard from './StoryFormCard.vue'
import { Story, DEFAULT_STORY } from '@/model/Story'

export default defineComponent({
  name: `AddStory`,
  components: { StoryFormCard },
  props: {},
  emits: ['add'],
  setup(props: Readonly<any>, context: SetupContext) {
    const story: Ref<Story> = ref(DEFAULT_STORY)
    const adding = ref(false)

    const addStory = (story: Story) => {
      context.emit('add', story)
    }

    const reset = () => {
      adding.value = false
      story.value = DEFAULT_STORY
    }

    return {
      story,
      adding,
      addStory,
      reset,
    }
  },
})
