
import {
  defineComponent,
  SetupContext,
  computed,
  ref,
  onMounted,
  watch,
  Ref,
} from 'vue'
import AddStory from '@/components/core/story/AddStory.vue'
import CardStory from '@/components/core/story/CardStory.vue'
import { Project } from '@/model/Project'
import { utils } from '@/common/Utils'
import { USER_ROLES } from '@/model/User'
import { Story } from '@/model/Story'
import { Toaster } from '@/common/Toaster'
import { storeAuth, StoreGettersAuth } from '@/store/auth'
import { storeProject, StoreGettersProject } from '@/store/project'
import { storeStory, StoreGettersStory, StoreActionsStory } from '@/store/story'
import { ResetableComponent } from '@/model/ResetableComponent'

export default defineComponent({
  name: `ProjectTabStory`,
  components: {
    AddStory,
    CardStory,
  },
  props: {
    projectId: {
      type: Number,
      required: true,
    },
  },
  emits: ['validate', 'cancel'],
  setup(
    props: Readonly<{
      projectId: number
    }>,
    context: SetupContext,
  ) {
    const addStoryEl: Ref<ResetableComponent> = ref(null)
    const loading = ref(false)
    const elementsPerPage = ref(12)
    const currentPage = ref(1)

    const isSuperadmin = computed(
      () => storeAuth.getters[StoreGettersAuth.ROLE] === USER_ROLES.SUPERADMIN,
    )
    const stories = computed(
      (): Story[] => storeStory.getters[StoreGettersStory.STORIES],
    )
    const sortedStories = computed(() => {
      return storeStory.getters[StoreGettersStory.STORIES].sort(
        (a: Story, b: Story) => {
          return utils.UTCToMoment(b.date).diff(utils.UTCToMoment(a.date))
        },
      ) as Story[]
    })
    const canAddStory = computed(
      () => storeAuth.getters[StoreGettersAuth.ROLE] === USER_ROLES.SUPERADMIN,
    )
    const project = computed(
      (): Project => storeProject.getters[StoreGettersProject.PROJECT],
    )

    watch(
      () => props.projectId,
      () => {
        if (!props.projectId) return
        if (props.projectId === project.value.id) return
        loadContent()
      },
    )

    onMounted(() => {
      loadContent()
    })

    const addStory = async (story: Story) => {
      const res = await storeStory.dispatch(StoreActionsStory.ADD, {
        ...story,
        projectId: props.projectId,
      })
      Toaster.toast(res)
      if (res.success) {
        addStoryEl.value.reset()
      }
    }

    const loadContent = async () => {
      loading.value = true
      const res = storeStory.dispatch(StoreActionsStory.SEARCH, {
        projectId: props.projectId,
      })
      res.then(() => (loading.value = false))
    }

    return {
      loading,
      addStoryEl,
      isSuperadmin,
      stories,
      sortedStories,
      canAddStory,
      addStory,
      elementsPerPage,
      currentPage,
    }
  },
})
